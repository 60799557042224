exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-data-analyst-tsx": () => import("./../../../src/pages/dataAnalyst.tsx" /* webpackChunkName: "component---src-pages-data-analyst-tsx" */),
  "component---src-pages-entia-tsx": () => import("./../../../src/pages/entia.tsx" /* webpackChunkName: "component---src-pages-entia-tsx" */),
  "component---src-pages-eventmarket-tsx": () => import("./../../../src/pages/eventmarket.tsx" /* webpackChunkName: "component---src-pages-eventmarket-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insight-tsx": () => import("./../../../src/pages/insight.tsx" /* webpackChunkName: "component---src-pages-insight-tsx" */),
  "component---src-pages-jns-tsx": () => import("./../../../src/pages/jns.tsx" /* webpackChunkName: "component---src-pages-jns-tsx" */),
  "component---src-pages-prototype-tsx": () => import("./../../../src/pages/prototype.tsx" /* webpackChunkName: "component---src-pages-prototype-tsx" */),
  "component---src-pages-services-system-tsx": () => import("./../../../src/pages/services/system.tsx" /* webpackChunkName: "component---src-pages-services-system-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-services-visuallab-tsx": () => import("./../../../src/pages/services/visuallab.tsx" /* webpackChunkName: "component---src-pages-services-visuallab-tsx" */),
  "component---src-pages-sodansumua-tsx": () => import("./../../../src/pages/sodansumua.tsx" /* webpackChunkName: "component---src-pages-sodansumua-tsx" */),
  "component---src-pages-ui-designer-tsx": () => import("./../../../src/pages/uiDesigner.tsx" /* webpackChunkName: "component---src-pages-ui-designer-tsx" */),
  "component---src-pages-wasaline-tsx": () => import("./../../../src/pages/wasaline.tsx" /* webpackChunkName: "component---src-pages-wasaline-tsx" */)
}

